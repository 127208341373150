import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  margin: auto;
  background: rgb(255 255 255 / 32%);
  border-radius: 60px;
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 99999;
  transition: top 0.5s ease-in;
  top: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-right: 2px solid rgb(255 255 255 / 60%);
  border-left: 2px solid rgb(255 255 255 / 60%);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  backdrop-filter: blur(31px);

  .ant-spin-dot-holder {
    font-size: 34px;
    color: #171717;
  }
  .ant-spin-dot-progress {
    width: 34px;
    height: 34px;
    top: -3px;
  }
`;

export default function Reload() {
  const [scrollY, setScrollY] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let timer;
    if (loadingView) {
      const duration = 2900;
      const interval = duration / 100;
      timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount < 100) {
            return prevCount + 1;
          } else {
            clearInterval(timer);
            return prevCount;
          }
        });
      }, interval);
      setTimeout(() => {
        window.location.reload();
        setCount(0);
        setLoadingView(false);
      }, 3000);
    }
    return () => clearInterval(timer);
  }, [loadingView]);

  const handleWheel = (e) => {
    setScrollY(e?.deltaY < -100);

    if (!loadingView && e?.deltaY < -400) {
      setLoadingView(true);
    } else if (loadingView && e?.deltaY > -400) {
      setLoadingView(false);
    }
  };

  useEffect(() => {
    document.getElementById("root").addEventListener("wheel", handleWheel);
    return () => {
      document.getElementById("root").removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <LoadingWrapper
      style={{
        top: scrollY || loadingView ? "10px" : "-141px",
      }}
    >
      <Spin percent={scrollY ? -10 : count} size="large" />
    </LoadingWrapper>
  );
}
