export const DefaultAdminPage = `/manage-user`;
export const DefaultUserPage = `/dashboard`;
export const Title = "CHEDDY-ANALYTICS_C4";
export const URL = "http://18.233.1.243/cheddy_php_v3";
export const ENDPOINT = `${URL}/v1/`;
export const APPID = "";
export const ClientID = "";
export const Types = {
  1: "admin",
  2: "user",
};
export const DefaultPerPage = 10;
export const FormatUserName = (userName) => {
  // Replace hyphens with spaces
  let formattedName = userName?.replace(/-/g, " ")?.split("_")?.join(" ");
  // Capitalize the first letter of each word
  formattedName = formattedName?.replace(/\b\w/g, function (char) {
    return char?.toUpperCase();
  });

  const replacements = [
    ["Roas", "ROAD"],
    ["Sns", "SNS"],
  ];
  return replacements.reduce(
    (name, [key, value]) => name?.replace(key, value),
    formattedName
  );
};
export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(
        ([_, value]) =>
          value &&
          value !== "" &&
          value !== undefined &&
          value !== null &&
          value?.length !== 0
      )
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);

        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
export const FormatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(number);
};
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const PropsFilter = (type) =>
  !type
    ? {}
    : {
        sorter: (a, b) => {
          return typeof a?.[type] === "string"
            ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
            : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
        },
      };
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
