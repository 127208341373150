import React from "react";
import { Table1Wrapper } from "./style";
import { Empty, Table } from "antd";

export default function Table1(props) {
  const { dataSource } = props;
  if (dataSource?.length === 0) {
    return (
      <Empty
        description={false}
        style={{
          height: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }
  return (
    <Table1Wrapper>
      <Table dataSource={dataSource} {...props} />
    </Table1Wrapper>
  );
}
