import * as am5 from "@amcharts/amcharts5";
import { Button, Empty, message, Select, Skeleton, Statistic, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";

import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { DotChartOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { MakeApiCall } from "../../../apis";
import RangePickerCF from "../../../Components/datepicker";
import MarketplaceSelect from "../../../Components/marketplace";
import { TableLoading } from "../../../Components/table-animation";
import Table1 from "../../../Components/tables/table1";
import { CFTooltip } from "../../../Components/tooltip";
import { ConvertParams, FormatNumber } from "../../../config";
import CountUp from "react-countup";

const formatterNumber = (value) => {
  const formattedString = value;
  const normalizedString = formattedString.replace(/,/g, ""); // Remove commas
  const number = parseFloat(normalizedString);
  // Format the number with commas and two decimal places
  return number;
};

const formatter = (value) => (
  <CountUp
    end={formatterNumber(value)}
    separator=","
    decimals={formatterNumber(value)?.toString().split(".")[1]?.length ? 2 : 0}
  />
);

export default function Insights({ userData }) {
  const sign = "$";
  const chartRef = useRef(null);
  const [activeTab, setActiveTab] = useState("top-10");
  const [cardsData, setCardsData] = useState({});
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [filtersList, setFiltersList] = useState({});
  const [performanceList, setPerformanceList] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    species: null,
    brand_id: null,
    product_line_id: null,
    sku: null,
    start_date: dayjs().add(-6, "d").subtract(1, "d").format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
    period_type: "month",
  });

  const [loaders, setLoaders] = useState({
    marketplace: true,
    filters: true,
    cards: true,
    performance: true,
  });

  const formatData = (data) => {
    const formattedData = [];
    let keyCounter = 1;

    for (const brandName in data) {
      if (data.hasOwnProperty(brandName)) {
        const brandData = data[brandName];
        const formattedBrand = {
          key: keyCounter.toString(),
          brand: brandName,
          children: [],
        };

        // Get all years dynamically
        const years = Object.keys(brandData);

        // Dynamically add year-specific fields for brand data
        years.forEach((year) => {
          formattedBrand[`sales${year}`] = `$${brandData[year].sales.toFixed(
            2
          )}`;
          formattedBrand[`units${year}`] = brandData[year].units;
          formattedBrand[`orders${year}`] = brandData[year].orders;
        });

        // Add child data dynamically

        brandData[years[years.length - 1]].child_data.forEach(
          (child, index) => {
            const formattedChild = {
              key: `${keyCounter}-${index + 1}`,
              brand: child.product_name,
            };

            // Dynamically add year-specific fields for child data
            years.forEach((year) => {
              if (brandData[year].child_data.some((c) => c.sku === child.sku)) {
                formattedChild[`sales${year}`] = `$${parseFloat(
                  child.sales
                ).toFixed(2)}`;
                formattedChild[`units${year}`] = parseInt(child.units);
                formattedChild[`orders${year}`] = child.orders;
                formattedChild[`image_url${year}`] = child.image_url;
                formattedChild["year"] = year;
                formattedChild["asin"] = child?.asin;
              } else {
                formattedChild[`sales${year}`] = `$0.00`;
                formattedChild[`units${year}`] = 0;
                formattedChild[`orders${year}`] = 0;
                formattedChild["year"] = year;
                formattedChild["asin"] = child?.asin;
              }
            });

            formattedBrand.children.push(formattedChild);
          }
        );

        formattedData.push(formattedBrand);
        keyCounter++;
      }
    }

    return formattedData;
  };
  const extractYears = (data) => {
    const yearsSet = new Set();

    for (const brandName in data) {
      if (data.hasOwnProperty(brandName)) {
        const brandData = data[brandName];
        for (const year in brandData) {
          if (brandData.hasOwnProperty(year)) {
            yearsSet.add(year);
          }
        }
      }
    }

    return Array.from(yearsSet).sort();
  };

  const userSettingFilter = async (data) => {
    try {
      setFiltersList({});

      setLoaders((prev) => ({
        ...prev,
        filters: true,
      }));
      const { start_date, species, end_date, period_type, ...rest } = data;
      const response = await MakeApiCall(
        `user-setting/filters${ConvertParams(rest)}`, // ${ConvertParams(data)}
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setFiltersList(response?.data);
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
      } else {
        setFiltersList({});
        setLoaders((prev) => ({
          ...prev,
          filters: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setFiltersList({});
      setLoaders((prev) => ({
        ...prev,
        filters: false,
      }));
    }
  };

  const DashboardCardData = async (data) => {
    try {
      setCardsData({});
      setLoaders((prev) => ({
        ...prev,
        cards: true,
      }));
      const { period_type, column_type, ...rest } = data;
      const response = await MakeApiCall(
        `insights-card-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setCardsData(response?.data || {});
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
      } else {
        setCardsData({});
        setLoaders((prev) => ({
          ...prev,
          cards: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setCardsData({});
      setLoaders((prev) => ({
        ...prev,
        cards: false,
      }));
    }
  };
  const DashboardPerformanceData = async (data) => {
    try {
      setPerformanceList({});
      setLoaders((prev) => ({
        ...prev,
        performance: true,
      }));
      const { period_type, column_type, ...rest } = data;
      const response = await MakeApiCall(
        `insights-performance-data${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.token
      );
      if (response?.status) {
        setPerformanceList(response?.data || {});
        setLoaders((prev) => ({
          ...prev,
          performance: false,
        }));
      } else {
        setPerformanceList({});
        setLoaders((prev) => ({
          ...prev,
          performance: false,
        }));
        message.warning(response?.message);
      }
    } catch (error) {
      message.warning(error?.message);
      setPerformanceList({});
      setLoaders((prev) => ({
        ...prev,
        performance: false,
      }));
    }
  };
  const FilterUpdate = (data) => {
    userSettingFilter(data);
  };
  const MassFire = async (data) => {
    try {
      await DashboardCardData({ ...data });
      await DashboardPerformanceData({ ...data });
    } catch (error) {
      console.error("Error in MassFire:", error);
    }
  };
  const callAfterMarketPlace = async (marketplace_id) => {
    try {
      await userSettingFilter({ marketplace_id });
      await DashboardCardData({ ...selectedFilters, marketplace_id });
      await DashboardPerformanceData({ ...selectedFilters, marketplace_id });
    } catch (error) {
      message.warning(error?.message);
    }
  };
  useEffect(() => {
    if (cardsData?.brand_data?.length === 0 || !chartRef.current) return;
    if (typeof cardsData?.brand_data === "undefined") return;
    am5.addLicense("AM5C1231231231");
    const root = am5.Root.new(chartRef.current);

    // Set themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        innerRadius: am5.percent(60),
        creditsDisabled: true,
      })
    );

    // Create series
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
        creditsDisabled: true,
      })
    );

    series.set(
      "colors",
      am5.ColorSet.new(root, {
        colors: [
          am5.color(0x0079ae),
          am5.color(0x333333),
          am5.color(0xf2aa6b),
          am5.color(0xf28f6b),
          am5.color(0xa95a52),
          am5.color(0xe35b5d),
          am5.color(0xffa446),
        ],
      })
    );

    const gradient = am5.RadialGradient.new(root, {
      stops: [
        { color: am5.color(0x000000) },
        { color: am5.color(0x000000) },
        {},
      ],
    });

    series.slices.template.setAll({
      fillGradient: gradient,
      strokeWidth: 2,
      stroke: am5.color(0xffffff),
      cornerRadius: 10,
      shadowOpacity: 0.1,
      shadowOffsetX: 2,
      shadowOffsetY: 2,
      shadowColor: am5.color(0x000000),
    });

    series.slices.template.states.create("hover", {
      shadowOpacity: 1,
      shadowBlur: 10,
    });
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    series.ticks.template.setAll({
      strokeOpacity: 0.4,
      strokeDasharray: [2, 2],
    });

    series.states.create("hidden", {
      endAngle: -90,
    });

    // Set data
    series.data.setAll(
      cardsData?.brand_data?.map((d) => ({
        category: d?.brand_name,
        value: parseFloat(parseFloat(d?.item_price || 0)?.toFixed(2)),
      }))
    );

    const legendRoot = am5.Root.new("legenddiv");

    // Create the legend
    const legend = legendRoot.container.children.push(
      am5.Legend.new(legendRoot, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.markerRectangles.template.adapters.add("fillGradient", function () {
      return undefined;
    });

    legend.data.setAll(series.dataItems);

    series.appear(1000, 100);

    return () => {
      legendRoot.dispose();
      root.dispose();
    };
  }, [cardsData?.brand_data]);

  const columns = [
    {
      title: "",
      dataIndex: "expand",
      key: "expand",
      width: 60,
      render: (_, record, index) => {
        if (record?.children) {
          return {
            props: {
              style: {
                padding: 15,
                opacity: record?.children ? 1 : 0,
                display: "flex",
                alignItems: "center",
              },
            },
          };
        }

        return (
          <div>
            <img
              width={60}
              height={60}
              loading="lazy"
              style={{ borderRadius: "10px" }}
              src={record?.[`image_url${record?.year}`]}
            />
          </div>
        );
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      fixed: "left",
      width: 500,
      render: (e, row) => {
        console.log(row, "row");

        return (
          <div className="d-flex justify-content-between align-items-center">
            <CFTooltip placement={"right"} row={2} rule>
              {e}
            </CFTooltip>
            {!row?.children && <b className="ms-3">{row?.asin || "-"}</b>}
          </div>
        );
      },
    },
  ];

  const cardView = [
    {
      icon: (
        <i className="ki-duotone ki-wallet fs-2x text-primary">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
        </i>
      ),
      title: "Total Sales",
      key: "total_sales",
    },
    {
      icon: (
        <i className="ki-duotone ki-watch fs-2x text-primary">
          <span className="path1" />
          <span className="path2" />
        </i>
      ),
      title: "Previous Sales",
      key: "previous_sales",
    },
    {
      icon: (
        <i className="ki-duotone ki-calendar-8 fs-2x text-primary">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
          <span className="path6" />
        </i>
      ),
      title: "Current Sales",
      key: "current_sales",
    },
  ];

  const SalesSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return (
        parseFloat(a[`sales${d}`]?.replace(/\$/g, "")) -
        parseFloat(b[`sales${d}`]?.replace(/\$/g, ""))
      );
    }
    if (b?.children && a?.children && !isExpanded) {
      return (
        parseFloat(a[`sales${d}`]?.replace(/\$/g, "")) -
        parseFloat(b[`sales${d}`]?.replace(/\$/g, ""))
      );
    }
  };

  const UnitsSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return a[`units${d}`] - b[`units${d}`];
    }
    if (b?.children && a?.children && !isExpanded) {
      return a[`units${d}`] - b[`units${d}`];
    }
  };

  const OrdersSort = (a, b, d) => {
    if (!b?.children && !a?.children && isExpanded) {
      return a[`orders${d}`] - b[`orders${d}`];
    }
    if (b?.children && a?.children && !isExpanded) {
      return a[`orders${d}`] - b[`orders${d}`];
    }
  };

  if (!loaders.marketplace && marketplaceList?.length === 0) return <Empty />;
  return (
    <div>
      <div className="d-flex flex-wrap gap-4 mb-7">
        <div className="position-relative">
          <MarketplaceSelect
            showSearch
            callAfterMarketPlace={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };

              callAfterMarketPlace(e);
              setSelectedFilters(obj);
            }}
            MarketplaceData={(e) => {
              setLoaders({
                ...loaders,
                marketplace: false,
              });

              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };

              setSelectedFilters(obj);

              setMarketplaceList(e);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              FilterUpdate(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
          />
        </div>
        <div className="position-relative">
          <RangePickerCF
            size="large"
            className="w-250px"
            id={Math.random()}
            needConfirm
            allowClear={false}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                start_date: dayjs(e[0]).format("YYYY-MM-DD"),
                end_date: dayjs(e[1]).format("YYYY-MM-DD"),
              };
              MassFire({ ...obj });
              setSelectedFilters(obj);
            }}
            value={[
              dayjs(selectedFilters?.start_date, "YYYY-MM-DD"),
              dayjs(selectedFilters?.end_date, "YYYY-MM-DD"),
            ]}
          />
        </div>

        <div className="position-relative">
          <Select
            size="large"
            className="w-150px"
            placeholder="Select Species"
            options={filtersList?.species}
            showSearch
            allowClear
            optionFilterProp="value"
            loading={loaders?.filters}
            value={selectedFilters?.species}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                species: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-275px"
            optionFilterProp="value"
            placeholder="Select Product Line"
            options={filtersList?.productLine}
            allowClear
            showSearch
            loading={loaders?.filters}
            value={selectedFilters?.product_line_id}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                product_line_id: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className="position-relative">
          <Select
            size="large"
            className="w-175px"
            optionFilterProp="value"
            placeholder="Select Brand"
            showSearch
            options={filtersList?.brand?.map((d) => ({
              ...d,
              label: (
                <div
                  style={{
                    maxWidth: "100%",
                    whiteSpace: "normal",
                    wordBreak: "break-all",
                    overflowWrap: "breakWord",
                    hyphens: "auto",
                  }}
                >
                  <div>{d?.label}</div>
                </div>
              ),
            }))}
            allowClear
            loading={loaders?.filters}
            value={selectedFilters?.brand_id}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                brand: e,
              };
              MassFire({ ...obj });
              FilterUpdate({ ...obj });
              setSelectedFilters(obj);
            }}
          />
        </div>
        <div className=" rounded p-0 d-flex gap-5 ms-auto">
          {cardView?.map((d, i) => (
            <div
              key={i}
              className="d-flex align-items-center me-2 box-statics p-2 rounded ps-4 pe-7"
            >
              <div className="symbol symbol-40px me-5">
                <div className="symbol-label bg-light-primary shadow-sm">
                  {loaders?.cards ? (
                    <Skeleton.Avatar active shape={"circle"} />
                  ) : (
                    d?.icon
                  )}
                </div>
              </div>
              <div>
                <div className="fs-5 text-gray-900 fw-bolder">
                  {loaders?.cards ? (
                    <Skeleton.Button
                      active
                      size="small"
                      style={{ height: "15px" }}
                    />
                  ) : (
                    <>
                      <Statistic
                        value={FormatNumber(
                          cardsData?.card_data?.[d?.key] || 0
                        )}
                        precision={2}
                        formatter={formatter}
                        valueRender={(e) => (
                          <div className="fs-5 text-gray-900 fw-bolder">
                            {sign || ""}
                            {e}
                          </div>
                        )}
                      />
                    </>
                  )}
                </div>
                <div className="fs-7 text-gray-800 fw-bold">
                  {loaders?.cards ? (
                    <Skeleton.Input active size="small" />
                  ) : (
                    d?.title
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-9 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header  mb-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Sales Overview
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar cts-tab">
                <ul className="nav nav-pills nav-pills-custom mb-0 mt-3">
                  {/*begin::Item*/}
                  <li
                    className="nav-item mb-0 me-3 me-lg-6"
                    role="presentation"
                  >
                    {/*begin::Link*/}
                    <a
                      className={`nav-link d-flex justify-content-between flex-row flex-center  h-50px py-4  ${
                        activeTab === "top-10" ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveTab("top-10");
                      }}
                      tabIndex={-1}
                    >
                      <div className="nav-icon me-7">
                        <i
                          className="ki-outline ki-chart-line-down chart-line-up-2
                                                                  fs-1"
                        />
                      </div>
                      <span className="nav-text fw-bold fs-6 lh-1">
                        Top 10 Sales
                      </span>
                    </a>
                    {/*end::Link*/}
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li className="nav-item mb-0" role="presentation">
                    {/*begin::Link*/}
                    <a
                      className={`nav-link d-flex justify-content-between flex-row flex-center  h-50px py-4  ${
                        activeTab === "bottom-10" ? "active" : ""
                      }`}
                      onClick={() => {
                        setActiveTab("bottom-10");
                      }}
                    >
                      <div className="nav-icon me-7">
                        <i className="ki-outline ki-chart-line-down fs-1" />
                      </div>
                      <span className="nav-text fw-bold fs-6 lh-1">
                        Bottom 10 Sales
                      </span>
                    </a>
                    {/*end::Link*/}
                  </li>
                  {/*end::Item*/}
                </ul>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pb-5  pt-4 pe-0">
              <div className="scroll overflow-x-hidden h-300px px-0 pe-5">
                <div className="row g-5">
                  {cardsData?.sales_overview?.[
                    activeTab === "top-10" ? "top_products" : "bottom_products"
                  ]?.map((d, i) => (
                    <div className="col-md-4">
                      <div className="card  overlay overflow-hidden bdr-box border-0 bg-light">
                        <div className="card-body p-0">
                          <div className="overlay-wrapper  h-100px p-5">
                            <div className="text-center d-flex align-items-center justify-content-between">
                              <div className="symbol symbol-55px bg-white shadow-sm overflow-hidden me-5">
                                <img
                                  src={
                                    d?.image_url ||
                                    "https://rakanonline.com/wp-content/uploads/2022/08/default-product-image.png"
                                  }
                                  className=" bg-light"
                                  loading="lazy"
                                  alt
                                />
                              </div>
                              <div className="d-flex flex-column w-100">
                                <div className="d-flex flex-stack mb-2">
                                  <span className="text-start fs-8">
                                    <b>ASIN:</b>{" "}
                                    <span className="badge py-2 px-4 ms-3 fs-7 badge-primary">
                                      <b>{d?.asin}</b>
                                    </span>
                                  </span>
                                  <span className="badge py-2 px-4 fs-7 badge-light-primary">
                                    ${FormatNumber(d?.item_price)}
                                  </span>
                                </div>
                                <a
                                  href
                                  className="text-gray-800 fs-6 text-hover-primary mb-0 fw-bold text-two min-w-200px text-start"
                                >
                                  <CFTooltip placement={"bottom"} row={2} rule>
                                    {d?.product_name || ""}
                                  </CFTooltip>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {loaders?.cards &&
                    Array(10)
                      ?.fill(1)
                      ?.map((d, i) => (
                        <div key={i} className="col-md-4">
                          <div className="card  overlay overflow-hidden bdr-box border-0 bg-light">
                            <div className="card-body p-0">
                              <div className="overlay-wrapper  h-100px p-5">
                                <div className="text-center d-flex align-items-center justify-content-between">
                                  <div className="d-flex flex-column w-100">
                                    <Skeleton
                                      avatar
                                      paragraph={{
                                        rows: 3,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
            {/*end::Body*/}
          </div>
          {/*end::Chart widget 31*/}
        </div>

        <div className="col-xl-3 mb-5 mb-xl-5">
          <div className="card card-flush h-xl-100 mb-0">
            <div className="card-header mb-0">
              <h3 className="card-title pt-0 align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Brand</span>
              </h3>

              <div className="card-toolbar"></div>
            </div>

            <div className="card-body px-2 pb-0 pt-0">
              {loaders?.cards ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "-webkit-fill-available",
                    height: "300px",
                  }}
                >
                  <Skeleton.Node fullSize={true} active>
                    <DotChartOutlined
                      style={{
                        fontSize: 10,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              ) : (
                <>
                  <div
                    ref={chartRef}
                    style={{ width: "100%", height: "225px" }}
                  />

                  <div className="h-75px overflow-auto">
                    <div id="legenddiv" className="w-100 h-175px "></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-5 mb-xl-5">
          {/*begin::Chart widget 31*/}
          <div className="card card-flush h-xl-100">
            {/*begin::Header*/}
            <div className="card-header  mb-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Year wise Performance
                </span>
                {/* <span class="text-gray-500 mt-1 fw-semibold fs-6">8k social visitors</span> */}
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar"></div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body  pb-0 pt-0 ">
              {loaders?.performance ? (
                <TableLoading
                  id="test-table"
                  row={10}
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={false}
                  actions={[]}
                  style={{ height: "calc(100vh - 500px)", overflow: "hidden" }}
                />
              ) : (
                <Table1
                  columns={[
                    ...columns,
                    ...extractYears(performanceList?.performance_data || {})
                      ?.sort((a, b) => b - a)
                      ?.map((d) => ({
                        title: `Year ${d}`,
                        children: [
                          {
                            title: "Sales",
                            dataIndex: `sales${d}`,
                            key: `sales${d}`,
                            align: "center",
                            sorter: (a, b) => SalesSort(a, b, d),
                            render: (e) => {
                              return (
                                <>${FormatNumber(e?.replace("$", "") || 0)}</>
                              );
                            },
                          },
                          {
                            title: "Units",
                            dataIndex: `units${d}`,
                            key: `units${d}`,
                            align: "center",
                            sorter: (a, b) => UnitsSort(a, b, d),
                            render: (e) => {
                              return <>{FormatNumber(e || 0)}</>;
                            },
                          },
                          {
                            title: "Orders",
                            dataIndex: `orders${d}`,
                            key: `orders${d}`,
                            align: "center",
                            sorter: (a, b) => OrdersSort(a, b, d),
                            render: (e) => {
                              return <>{FormatNumber(e || 0)}</>;
                            },
                          },
                        ],
                      })),
                  ]}
                  dataSource={formatData(
                    performanceList?.performance_data || {}
                  )}
                  rowKey={"key"}
                  // scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
                  pagination={false}
                  // virtual
                  expandable={{
                    indentSize: 0,
                    expandIconColumnIndex: 0, // Change this to the index of the column where you want the icon
                    expandIcon: ({ expanded, onExpand, record }) =>
                      record?.children ? (
                        <Button
                          icon={
                            <div
                              className="symbol symbol-30px ms-5 cursor-pointer"
                              style={{
                                transform: `rotate(${expanded ? 90 : 0}deg)`,
                              }}
                            >
                              <span className="symbol-label accordion-icon bg-light-primary">
                                <i className="ki-outline ki-right fs-3 text-gray-600" />
                              </span>
                            </div>
                          }
                          onClick={(e) => {
                            onExpand(record, e);
                            setIsExpanded(!isExpanded);
                          }}
                          size="small"
                          style={{ border: "none", background: "none" }}
                        />
                      ) : (
                        ""
                      ),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
