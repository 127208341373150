import { Spin, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import moment from "moment";
import AdsAmazon from "./lib/ads-amazon";
import AdsProfiles from "./lib/ads-profiles";
import SpModal from "./lib/sp-modal";
import { TabWrapper } from "../dashboard/style";

const MarketplaceView = (props) => {
  const {
    APPID,
    SPCallBack,
    generateRefreshToken,
    list,
    AdsCallBack,
    ClientID,
    AdsRefreshToken,
    saveProfile,
    returnURL,
    MakeApiCall,
    userData,
    urlDecode,
  } = props;
  const [spList, setSpList] = useState([]);
  const [spLoading, setSpLoading] = useState(true);
  const [selectedType, setSelectedType] = useState("1");
  const [spModal, setSpModal] = useState(false);
  const location = useLocation();

  const [adsModalOpen, setAdsModalOpen] = useState(false);

  const [adList, setAdList] = useState([]);
  const [adListModal, setAdListModal] = useState(false);

  const getSPList = async () => {
    if (!list) return;
    setSpLoading(true);
    const response = await MakeApiCall(
      list,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      setSpLoading(false);
      setSpList(response?.data?.records || []);
    } else {
      message.destroy();
      message.warning(response?.message);
      setSpLoading(false);
      setSpList([]);
    }
  };
  const GenerateRefreshToken = async (data) => {
    setSpLoading(true);
    const response = await MakeApiCall(
      generateRefreshToken,
      "post",
      data,
      true,
      {},
      userData?.token
    );

    if (response?.status) {
      message.success(response?.message);
      message.destroy();
      setTimeout(() => {
        window.location.assign(SPCallBack);
      }, 500);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const AdsGenerateRefreshToken = async (data) => {
    const response = await MakeApiCall(
      AdsRefreshToken,
      "post",
      data,
      true,
      {},
      userData?.token
    );
    try {
      if (response?.status) {
        message.success(response?.message);
        message.destroy();
        setAdList(JSON.parse(response?.data));
        setAdListModal(true);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.warning("Something went wrong");
    }
  };

  useEffect(() => {
    if (location.pathname.includes(SPCallBack)) {
      setSelectedType("1");
      if (location.search) {
        const { spapi_oauth_code, selling_partner_id, state } =
          urlDecode(location);
        const obj = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          seller_name: decodeURIComponent(state?.split("!!")?.[1] || ""),
          marketplace_id: state?.split("!!")?.[3] || "",
          other_marketplace_ids: localStorage.getItem("other_marketplace")
            ? JSON.parse(localStorage.getItem("other_marketplace"))?.join(",")
            : "",
        };
        message.destroy();
        message.loading("Loading...", 0);
        GenerateRefreshToken(obj);
      }
    } else if (location.pathname.includes(AdsCallBack)) {
      setSelectedType("2");
      if (location.search) {
        const { code, marketplace_id } = urlDecode(location);

        const obj = {
          ad_code: code,
          marketplace_id: marketplace_id,
        };

        message.destroy();
        message.loading("Loading...", 0);
        AdsGenerateRefreshToken(obj);
      }
    }
  }, [location]);

  useEffect(() => {
    getSPList();
    return () => {};
  }, []);

  return (
    <div id="" className="mt-3">
      <div id="kt_app_content_container">
        <div className="row">
          <div className="col-md-12 cus-tab">
            <TabWrapper className="card-toolbar">
              <Tabs
                className="w-550px"
                onChange={(e) => {
                  setSelectedType(e);
                }}
                activeKey={selectedType}
              >
                <Tabs.TabPane
                  tab="Amazon SP API Credentials"
                  key="1"
                ></Tabs.TabPane>
                <Tabs.TabPane
                  tab="Amazon Advertising Credentials"
                  key="2"
                ></Tabs.TabPane>
              </Tabs>
            </TabWrapper>
          </div>
        </div>

        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${
              selectedType === "1" ? "active show" : ""
            } `}
            id="kt_tab_pane_7"
            role="tabpanel"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark gotham_black">
                        Amazon SP API Credentials List
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="d-flex flex-stack flex-wrap gap-4">
                        <div>
                          <a
                            onClick={() => setSpModal(true)}
                            className="btn btn-warning fs-7 fw-bolder text-dark"
                          >
                            <i className="fab fa-amazon text-dark fs-2" /> Login
                            with Amazon
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body py-2">
                    {spLoading ? (
                      <div className="min-h-500px d-flex align-items-center justify-content-center">
                        <Spin />
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                          <thead>
                            <tr className="fw-bolder text-dark bg-gray-cus">
                              <th className="w-50px">#</th>
                              <th className="min-w-175px">
                                Seller Account Name
                              </th>
                              <th className="min-w-175px">ARN</th>
                              <th className="min-w-175px">Region</th>
                              <th className="min-w-175px">Marketplace</th>
                              <th className="min-w-175px">Created At</th>
                              <th className="min-w-175px">Updated At</th>
                            </tr>
                          </thead>

                          <tbody>
                            {spList?.["SP-API"]?.map((d, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{decodeURIComponent(d?.seller_name)}</td>
                                  <td>{d?.role_arn}</td>
                                  <td>{d?.region}</td>
                                  <td>{d?.marketplace}</td>

                                  <td>
                                    {moment(
                                      new Date(parseInt(d.created_at) * 1000)
                                    ).format("MM/DD/YYYY hh:mm A")}
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(parseInt(d.updated_at) * 1000)
                                    ).format("MM/DD/YYYY hh:mm A")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              selectedType === "2" ? "active show" : ""
            } `}
            id="kt_tab_pane_8"
            role="tabpanel"
          >
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder text-dark gotham_black">
                        Amazon Advertising Credentials List
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <a
                        onClick={() => setAdsModalOpen(true)}
                        className="btn btn-warning fs-7 fw-bolder text-dark"
                      >
                        <i className="fab fa-amazon text-dark fs-2" /> Login
                        with Amazon
                      </a>
                    </div>
                  </div>

                  <div className="card-body py-2">
                    {spLoading ? (
                      <div className="min-h-500px d-flex align-items-center justify-content-center">
                        <Spin />
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                          <thead>
                            <tr className="fw-bolder text-dark bg-gray-cus">
                              <th className="min-w-75px">#</th>
                              <th className="min-w-200px">
                                Seller Account Name
                              </th>
                              <th className="min-w-100px">Profile ID</th>

                              <th className="min-w-125px">Country Code</th>
                              <th className="min-w-125px">Currency Code</th>
                              <th className="min-w-100px">Time Zone</th>
                              <th className="min-w-175px">Created At</th>
                              <th className="min-w-175px">Updated At</th>
                            </tr>
                          </thead>

                          <tbody>
                            {spList?.["Advertising-API"]?.map((d, i) => {
                              return (
                                <tr key={i}>
                                  <td>{i + 1}</td>

                                  <td>{d?.seller_name}</td>
                                  <td>{d?.profile_id}</td>
                                  <td>{d?.country_code}</td>
                                  <td>{d?.currency_code}</td>
                                  <td>{d?.time_zone}</td>
                                  <td>
                                    {moment(
                                      new Date(parseInt(d.created_at) * 1000)
                                    ).format("MM/DD/YYYY hh:mm A")}
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(parseInt(d.updated_at) * 1000)
                                    ).format("MM/DD/YYYY hh:mm A")}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="amazon-root" />
      {spModal && (
        <SpModal
          list={spList}
          view={spModal}
          APPID={APPID}
          onClose={() => {
            setSpModal(false);
          }}
          {...props}
        />
      )}
      {adsModalOpen && (
        <AdsAmazon
          returnURL={returnURL}
          ClientID={ClientID}
          show={adsModalOpen}
          close={() => setAdsModalOpen(false)}
          {...props}
        />
      )}
      {adListModal && (
        <AdsProfiles
          show={adListModal}
          saveProfile={saveProfile}
          data={adList}
          AdsCallBack={AdsCallBack}
          getList={() => {
            getSPList();
          }}
          onHide={() => {
            setAdListModal(false);
          }}
          {...props}
        />
      )}
    </div>
  );
};

export default MarketplaceView;
