export const UserMenus = [
  {
    label: "Dashboard",
    key: "dashboard",
  },
  {
    label: "Insights",
    key: "insights",
  },
  {
    label: "Variance",
    key: "variance",
  },
  {
    label: "Purchase",
    key: "purchase",
  },
  {
    label: "Inventory",
    key: "inventory",
  },
  {
    label: "Subscribe & Save",
    key: "subscribe-&-save",
  },
  {
    label: "Financial Summary",
    key: "financial-summary",
  },
  {
    label: "Item Master",
    key: "item-master",
  },
  {
    label: "Purchase Order",
    key: "purchase-order",
    children: [
      {
        label: "Create Order",
        key: "create-order",
      },
      {
        label: "PO List",
        key: "po-list",
      },
    ],
  },
];
