import React, { Children } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Divider,
  Dropdown,
  Image,
  Input,
  Menu,
  Select,
  Table,
  Tag,
} from "antd";
import Table2 from "../../../Components/tables/table2";
import Table1 from "../../../Components/tables/table1";
import { PropsFilter } from "../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";

const generateData = (numRows) => {
  const randomNumber = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

  return Array.from({ length: numRows }, (d, i) => ({
    key: i + 1,
    detail: "Sample Detail",
    image: "https://via.placeholder.com/50",
    itemDescription: `Sample Item ${randomNumber(1, 100)}`,
    moq: randomNumber(5, 20),
    asin: `B000${randomNumber(10000, 99999)}`,
    fnsku: `X000${randomNumber(10000, 99999)}`,
    msku: `MSKU${randomNumber(1000, 9999)}`,
    asinType: randomNumber(0, 1) ? "Standard" : "Oversize",
    fbaFee: (randomNumber(20, 50) / 10).toFixed(2),
    mapPrice: (randomNumber(10, 30) / 1).toFixed(2),
    sellerFee: (randomNumber(10, 20) / 10).toFixed(2),
    storageFee: (randomNumber(5, 10) / 10).toFixed(2),
    totalFee: (randomNumber(50, 100) / 10).toFixed(2),
    fulfillmentType: randomNumber(0, 1) ? "FBA" : "FBM",
    prepType: randomNumber(0, 1) ? "Label" : "Polybag",
    asinCost: (randomNumber(20, 50) / 10).toFixed(2),
    unitCostHistory: `${(randomNumber(20, 50) / 10).toFixed(2)}, ${(
      randomNumber(20, 50) / 10
    ).toFixed(2)}`,
    brand: `Brand ${randomNumber(1, 100)}`,
    repricerType: randomNumber(0, 1) ? "Dynamic" : "Fixed",
    supplyVendor: `Vendor ${randomNumber(1, 100)}`,
    asinNote: "High Demand",
    dateCreated: new Date().toISOString().split("T")[0],
    productGroup: "Electronics",
    productLine: randomNumber(0, 1) ? "Mobile" : "Accessories",
    productShortDescription: "High-quality product",
    attribute1: `Color: ${randomNumber(0, 1) ? "Black" : "White"}`,
    attribute2: `Size: ${randomNumber(0, 1) ? "Medium" : "Large"}`,
    attribute3: `Weight: ${randomNumber(100, 500)}g`,
    children: [],
  }));
};

const ItemMaster = () => {
  const columns = [
    {
      title: "",
      fixed: "left",
      align: "center",
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      fixed: "left",
      align: "center",
      render: (e) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <Icon
              icon="ri:amazon-fill"
              width={26}
              style={{ color: "orange" }}
            />
            <Icon icon="solar:tag-bold-duotone" width={26} />
            <Icon icon="flowbite:edit-solid" width={26} />
          </div>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      fixed: "left",
      key: "image",
      render: (text) => <Image width={50} src={text} />,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => <b>{text}</b>,
      fixed: "left",
    },
    {
      title: "MOQ",
      dataIndex: "moq",
      key: "moq",
      render: (text) => (
        <Tag color="blue" style={{ borderRadius: "10px" }}>
          {text}
        </Tag>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => (
        <Tag color="green" style={{ borderRadius: "10px" }}>
          {text}
        </Tag>
      ),
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
      render: (text) => (
        <Tag color="orange" style={{ borderRadius: "10px" }}>
          {text}
        </Tag>
      ),
    },
    {
      title: "MSKU",
      dataIndex: "msku",
      key: "msku",
      render: (text) => (
        <Tag color="purple" style={{ borderRadius: "10px" }}>
          {text}
        </Tag>
      ),
    },
    { title: "ASIN Type", dataIndex: "asinType", key: "asinType" },
    { title: "FBA Fee", dataIndex: "fbaFee", key: "fbaFee" },
    {
      title: "MAP Price",
      dataIndex: "mapPrice",
      key: "mapPrice",
      render: (text) => <Input variant="filled" value={text} />,
    },
    { title: "Seller Fee", dataIndex: "sellerFee", key: "sellerFee" },
    { title: "Storage Fee", dataIndex: "storageFee", key: "storageFee" },
    { title: "Total Fee", dataIndex: "totalFee", key: "totalFee" },
    {
      title: "Fulfillment Type",
      dataIndex: "fulfillmentType",
      key: "fulfillmentType",
    },
    {
      title: "Prep Type",
      dataIndex: "prepType",
      key: "prepType",
      render: (text) => (
        <a
          href={`/item-master/link-type?linkType=${text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ),
    },
    {
      title: "ASIN Cost",
      dataIndex: "asinCost",
      key: "asinCost",
      render: (text, record) => (
        <div>
          <span>${text}</span>
          <Button
            type="link"
            size="small"
            icon={<Icon icon="uim:clock" width={24} />}
            className="ms-3"
            onClick={() => {
              window.open(
                `https://vendorcentral.amazon.com/inv-performance?asin=${record.asin}`,
                "_blank"
              );
            }}
          ></Button>
        </div>
      ),
    },
    {
      title: "Unit Cost History",
      dataIndex: "unitCostHistory",
      key: "unitCostHistory",
      render: (text, record) => (
        <div>
          <Button
            type="link"
            size="small"
            icon={<Icon icon="uim:clock" width={24} />}
            className="ms-3"
            onClick={() => {
              window.open(
                `https://vendorcentral.amazon.com/inv-performance?asin=${record.asin}`,
                "_blank"
              );
            }}
          ></Button>
        </div>
      ),
    },
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Repricer Type", dataIndex: "repricerType", key: "repricerType" },
    { title: "Supply Vendor", dataIndex: "supplyVendor", key: "supplyVendor" },
    {
      title: "ASIN Note",
      dataIndex: "asinNote",
      key: "asinNote",
      align: "center",
      render: (text) => (
        <div>
          <Button type="dashed" size="small">
            <Icon icon="si:add-fill" width={23} />
          </Button>
        </div>
      ),
    },
    { title: "Date Created", dataIndex: "dateCreated", key: "dateCreated" },
    { title: "Product Group", dataIndex: "productGroup", key: "productGroup" },
    {
      title: "Product Line",
      dataIndex: "productLine",
      key: "productLine",
      render: (text) => <Input variant="filled" value={text} />,
    },
    {
      title: "Product Short Description",
      dataIndex: "productShortDescription",
      key: "productShortDescription",
      render: (text) => <Input variant="filled" value={text} />,
    },
    {
      title: "Attribute1",
      dataIndex: "attribute1",
      key: "attribute1",
      render: (text) => <Input variant="filled" value={text} />,
    },
    {
      title: "Attribute2",
      dataIndex: "attribute2",
      key: "attribute2",
      render: (text) => <Input variant="filled" value={text} />,
    },
    {
      title: "Attribute3",
      dataIndex: "attribute3",
      key: "attribute3",
      render: (text) => <Input variant="filled" value={text} />,
    },
  ];

  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-2">
          <Select size="large" placeholder="Status" />
          <Input
            className="w-300px"
            placeholder="Search by Brand, Prep Type, Title"
          />
          <Select size="large" placeholder="Saved Filter" />
          <Button type="primary">
            <Icon icon="oui:export" /> Export
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">Days of Stock</Menu.Item>
                <Menu.Item key="2">Add ASIN Lead Time</Menu.Item>
                <Menu.Item key="3">Add ASIN Note</Menu.Item>
                <Menu.Item key="4">Add Pericer Type</Menu.Item>
                <Menu.Item key="5">Add Supply Type</Menu.Item>
                <Menu.Item key="6">Enable Listing</Menu.Item>
                <Menu.Item key="7">Desable Listing</Menu.Item>
                <Menu.Item key="8">Set Brand</Menu.Item>
                <Menu.Item key="9">Set Vendor</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button type="primary">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
        </div>
        <div className="d-flex gap-2">
          <Button type="dashed" href="/component-list">
            <Icon icon="icon-park-outline:list" width={20} />
            Components List
          </Button>
          <Button type="primary">
            <Icon icon="lets-icons:add-duotone" width={24} /> Add Components
          </Button>
        </div>
      </div>
      <div className="card p-3 mt-3">
        <div className="card-body p-0">
          <Table1
            columns={columns?.map((d) => ({
              ...d,
              ...PropsFilter(d?.dataIndex),
            }))}
            dataSource={generateData(50)}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {},
            }}
            expandIcon={({ expanded, onExpand, record }) => (
              <Button
                icon={
                  <div
                    className="symbol symbol-30px ms-5 cursor-pointer"
                    style={{
                      transform: `rotate(${expanded ? 90 : 0}deg)`,
                    }}
                  >
                    <span className="symbol-label accordion-icon bg-light-primary">
                      <Icon icon="mingcute:link-fill" />
                    </span>
                  </div>
                }
                onClick={(e) => onExpand(record, e)}
                size="small"
                style={{ border: "none", background: "none" }}
              />
            )}
            scroll={{ x: "max-content" }}
          />{" "}
        </div>
      </div>
    </Wrapper>
  );
};

export default ItemMaster;
