import React, { Children } from "react";
import { Wrapper } from "./style";
import { Badge, Button, Dropdown, Image, Input, Menu, Select, Tag } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import Table1 from "../../../Components/tables/table1";
import { PropsFilter } from "../../../config";

const CreateOrder = () => {
  const columns = [
    {
      title: "",
      fixed: "left",
      align: "center",
    },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      fixed: "left",
      align: "center",
      render: (e) => {
        return (
          <div className="d-flex align-items-center gap-3">
            <Icon
              icon="ri:amazon-fill"
              width={26}
              style={{ color: "orange" }}
            />
            <Icon icon="solar:tag-bold-duotone" width={26} />
            <Icon icon="flowbite:edit-solid" width={26} />
          </div>
        );
      },
    },
    {
      title: "Replenishment",
      dataIndex: "replenishment",
      key: "replenishment",
      render: (e) => {
        return (
          <div className="d-flex align-items-center gap-2">
            <Button
              type="primary"
              shape="round"
              style={{ background: "#41d682" }}
              size="small"
            >
              {e}
            </Button>

            <Button type="link" icon={<Icon icon="tabler:edit" width={18} />} />
          </div>
        );
      },
    },
    {
      title: "Product Short Description",
      dataIndex: "productShortDescription",
      key: "productShortDescription",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "In-Transit Inventory",
      dataIndex: "inTransitInventory",
      key: "inTransitInventory",
      align: "center",
      render: (e) => (
        <Badge
          count={e}
          style={{
            backgroundColor: "#f0f0f0",
            color: "#333",
            borderRadius: "10px",
          }}
        />
      ),
    },
    { title: "On Order", dataIndex: "onOrder", key: "onOrder" },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (text) => (
        <Tag color="success" style={{ borderRadius: "10px" }}>
          {text}
        </Tag>
      ),
    },
    { title: "Sells Out In", dataIndex: "sellsOutIn", key: "sellsOutIn" },
    { title: "Sells Out", dataIndex: "sellsOut", key: "sellsOut" },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => <Image width={50} src={image} />,
    },
    {
      title: "Item Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      render: (text) => <b>{text}</b>,
    },
    {
      title: "Sale Velocity/Day",
      dataIndex: "saleVelocityDay",
      key: "saleVelocityDay",
    },
    {
      title: "Sale Velocity/Month",
      dataIndex: "saleVelocityMonth",
      key: "saleVelocityMonth",
    },
    { title: "Product Group", dataIndex: "productGroup", key: "productGroup" },
    { title: "Profit Margin", dataIndex: "profitMargin", key: "profitMargin" },
    { title: "Profit", dataIndex: "profit", key: "profit" },
    { title: "Units Sold/Day", dataIndex: "unitsSoldDay", key: "unitsSoldDay" },
    {
      title: "Available Inventory",
      dataIndex: "availableInventory",
      key: "availableInventory",
    },
    { title: "FNSKU", dataIndex: "fnsku", key: "fnsku" },
    { title: "MSKU", dataIndex: "msku", key: "msku" },
    { title: "MOQ", dataIndex: "moq", key: "moq" },
    { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    { title: "FBA Fee", dataIndex: "fbaFee", key: "fbaFee" },
    { title: "Seller Fee", dataIndex: "sellerFee", key: "sellerFee" },
    { title: "Storage Fee", dataIndex: "storageFee", key: "storageFee" },
    { title: "Total Fee", dataIndex: "totalFee", key: "totalFee" },
    { title: "Prep Type", dataIndex: "prepType", key: "prepType" },
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "ASIN Cost", dataIndex: "asinCost", key: "asinCost" },
    {
      title: "Unit Cost History",
      dataIndex: "unitCostHistory",
      key: "unitCostHistory",
    },
    { title: "ASIN Note", dataIndex: "asinNote", key: "asinNote" },
    {
      title: "Inventory Restriction",
      dataIndex: "inventoryRestriction",
      key: "inventoryRestriction",
    },
    { title: "Open Date", dataIndex: "openDate", key: "openDate" },
    {
      title: "Total Inventory",
      dataIndex: "totalInventory",
      key: "totalInventory",
    },
    {
      title: "Reserved Inventory",
      dataIndex: "reservedInventory",
      key: "reservedInventory",
    },
    { title: "Inbound QTY", dataIndex: "inboundQty", key: "inboundQty" },
    { title: "Days in Stock", dataIndex: "daysInStock", key: "daysInStock" },
    {
      title: "Fulfillment Type",
      dataIndex: "fulfillmentType",
      key: "fulfillmentType",
    },
    { title: "Attribute 1", dataIndex: "attribute1", key: "attribute1" },
    { title: "Attribute 2", dataIndex: "attribute2", key: "attribute2" },
    { title: "Attribute 3", dataIndex: "attribute3", key: "attribute3" },
  ];

  const data = Array.from({ length: 10 }, (_, i) => ({
    key: i,
    detail: `Detail ${i + 1}`,
    replenishment: Math.floor(Math.random() * 100),
    productShortDescription: `Product ${i + 1} Short Description`,
    inTransitInventory: Math.floor(Math.random() * 200),
    onOrder: Math.floor(Math.random() * 150),
    asin: `B00${Math.floor(100000 + Math.random() * 900000)}`,
    sellsOutIn: `${Math.floor(Math.random() * 30)} days`,
    sellsOut: `Expected ${new Date().toLocaleDateString()}`,
    image: "https://via.placeholder.com/50",
    itemDescription: `Item ${i + 1} description`,
    saleVelocityDay: (Math.random() * 10).toFixed(2),
    saleVelocityMonth: (Math.random() * 300).toFixed(2),
    productGroup: `Group ${i + 1}`,
    profitMargin: `${(Math.random() * 20).toFixed(2)}%`,
    profit: `$${(Math.random() * 500).toFixed(2)}`,
    unitsSoldDay: (Math.random() * 20).toFixed(1),
    availableInventory: Math.floor(Math.random() * 500),
    fnsku: `FN${i + 1}SKU`,
    msku: `M${i + 1}SKU`,
    moq: Math.floor(Math.random() * 50),
    vendor: `Vendor ${i + 1}`,
    fbaFee: `$${(Math.random() * 5).toFixed(2)}`,
    sellerFee: `$${(Math.random() * 5).toFixed(2)}`,
    storageFee: `$${(Math.random() * 2).toFixed(2)}`,
    totalFee: `$${(Math.random() * 12).toFixed(2)}`,
    prepType: `Type ${i + 1}`,
    brand: `Brand ${i + 1}`,
    asinCost: `$${(Math.random() * 100).toFixed(2)}`,
    unitCostHistory: `$${(Math.random() * 100).toFixed(2)}`,
    asinNote: `Note ${i + 1}`,
    inventoryRestriction: i % 2 === 0 ? "Restricted" : "Allowed",
    openDate: new Date().toLocaleDateString(),
    totalInventory: Math.floor(Math.random() * 800),
    reservedInventory: Math.floor(Math.random() * 100),
    inboundQty: Math.floor(Math.random() * 300),
    daysInStock: Math.floor(Math.random() * 365),
    fulfillmentType: i % 2 === 0 ? "FBA" : "FBM",
    attribute1: `Attribute ${i + 1}-1`,
    attribute2: `Attribute ${i + 1}-2`,
    attribute3: `Attribute ${i + 1}-3`,
    children: [],
  }));
  return (
    <Wrapper>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-2 flex-wrap">
          <Select size="large" placeholder="Status" />
          <Input
            className="w-300px"
            placeholder="Search by Brand, Prep Type, Title"
          />
          <Select size="large" placeholder="Saved Filter" />

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">Add Item Note</Menu.Item>
                <Menu.Item key="2">Set as Replenishment</Menu.Item>
                <Menu.Item key="3">Set as Non-Replenishment</Menu.Item>
                <Menu.Item key="4">Set Lead Time</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button type="primary">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
        </div>
        <div className="d-flex gap-2">
          <Button type="dashed">
            <Icon icon="material-symbols:download" width={20} />
            Forecast Template
          </Button>
          <Button type="primary">
            <Icon icon="fluent-mdl2:web-template" width={22} /> PO Template
          </Button>
          <Button type="primary">
            <Icon icon="icon-park-outline:list" width={22} /> PO List
          </Button>
        </div>
      </div>

      <div className="card p-3 mt-3">
        <div className="card-body p-0">
          <Table1
            columns={columns?.map((d) => ({
              ...d,
              ...PropsFilter(d?.dataIndex),
            }))}
            dataSource={data}
            rowSelection={{
              type: "checkbox",
              onChange: (selectedRowKeys, selectedRows) => {},
            }}
            expandIcon={({ expanded, onExpand, record }) => (
              <Button
                icon={
                  <div
                    className="symbol symbol-30px ms-5 cursor-pointer"
                    style={{
                      transform: `rotate(${expanded ? 90 : 0}deg)`,
                    }}
                  >
                    <span className="symbol-label accordion-icon bg-light-primary">
                      <Icon icon="mingcute:link-fill" />
                    </span>
                  </div>
                }
                onClick={(e) => onExpand(record, e)}
                size="small"
                style={{ border: "none", background: "none" }}
              />
            )}
            scroll={{ x: "max-content" }}
          />{" "}
        </div>
      </div>
    </Wrapper>
  );
};

export default CreateOrder;
