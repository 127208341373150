import React from "react";
import { Table2Wrapper } from "./style";
import { Empty, Table } from "antd";

export default function Table2(props) {
  const { dataSource, loading } = props;
  if (!loading && dataSource?.length === 0) {
    return (
      <Empty
        description={false}
        style={{
          height: 500,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      />
    );
  }
  return (
    <Table2Wrapper>
      <Table dataSource={dataSource} {...props} />
    </Table2Wrapper>
  );
}
