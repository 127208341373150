import { Input, message, Modal, Select } from "antd";
import React, { useState } from "react";

import { useEffect } from "react";
import { Link } from "react-router-dom";

const SpModal = ({ view, onClose, list, APPID, MakeApiCall, userData }) => {
  const [region, setRegion] = useState([]);

  const [regionSelected, setRegionSelected] = useState({
    url: null,
    value: null,
  });

  const [marketplaceSelected, setMarketplaceSelected] = useState(null);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(false);

  const [sellerName, setSellerName] = useState(
    userData?.user?.user_data?.seller_name || ""
  );

  const email = userData?.user?.user_data?.email || "";

  const getRegion = () => {
    getMarketplace();
    setMarketplaceLoading(true);
    return;
  };

  const getMarketplace = async (data) => {
    setMarketplaceLoading(true);

    const response = await MakeApiCall(
      `get-marketplaces`,
      "get",
      null,
      true,
      {},
      userData?.token
    );

    if (response?.status === true) {
      const groupedByRegion = response?.data?.all_marketplaces.reduce(
        (acc, item) => {
          if (!acc[item.region]) {
            acc[item.region] = [];
          }
          acc[item.region].push(item);
          return acc;
        },
        {}
      );

      const regionLabels = {};

      response?.data?.all_marketplaces.forEach((item) => {
        if (!regionLabels[item.region]) {
          regionLabels[item.region] = item.region_name;
        }
      });

      setRegion(
        Object.entries(regionLabels)?.map(([key, value]) => ({
          label: key,
          value: value,
        }))
      );
      setMarketplaceList(groupedByRegion);

      setMarketplaceLoading(false);
    } else {
      setMarketplaceLoading(false);
    }
  };
  console.log(
    marketplaceList?.[regionSelected?.label],
    regionSelected?.label,
    "setMarketplaceList"
  );

  useEffect(() => {
    getRegion();
    return () => {};
  }, []);

  console.log(regionSelected?.url, "regionSelected");
  return (
    <Modal
      title="Add New"
      open={view}
      width={750}
      centered
      footer={[
        <Link
          type="submit"
          id="kt_modal_add_customer_submit"
          className={`btn btn-warning fs-7 fw-bolder text-dark mt-10 ${
            Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
            !marketplaceSelected
              ? "btn-disable-primary"
              : ""
          }`}
          target="_blank"
          style={{
            pointerEvents:
              Object.values(regionSelected)?.filter((d) => !d)?.length !== 0 ||
              !marketplaceSelected
                ? "none"
                : "auto",
          }}
          to={`${
            regionSelected?.url || ""
          }/apps/authorize/consent?application_id=${APPID}&state=${email}!!${sellerName}!!${
            regionSelected?.value
          }!!${marketplaceSelected}&version=beta`}
          onClick={() => {
            if (
              Object.values(regionSelected)?.filter((d) => !d)?.length === 0
            ) {
              onClose();
              message.destroy();
            } else {
              message.destroy();
              return message.error(
                "please make sure that all required fields are not-empty"
              );
            }
          }}
        >
          <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
        </Link>,
      ]}
      onCancel={onClose}
    >
      <div className="gap-5 mt-10">
        <div className="row mb-5">
          <div className="col-4">Seller Account Name</div>
          <div className="col-8">
            <Input
              onChange={(e) => setSellerName(e.target.value)}
              value={sellerName}
              placeholder="Seller Account Name"
            />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-4">Region</div>
          <div className="col-8">
            <Select
              options={region}
              value={regionSelected?.value}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(e, r) => {
                setMarketplaceSelected(null);
                setRegionSelected({
                  label: r?.label,
                  value: r?.value,
                });
              }}
              placeholder="Select Region"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-4">Primary Marketplace</div>
          <div className="col-8">
            <Select
              style={{ width: "100%" }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onChange={(e, _) => {
                setRegionSelected({
                  ...regionSelected,
                  url: _?.sellercentral_url,
                });
                setMarketplaceSelected(e);
              }}
              placeholder="Select Primary Marketplace"
              options={marketplaceList?.[regionSelected?.label]?.map((d) => ({
                label: d?.name,
                value: d?.id,

                ...d,
              }))}
              value={marketplaceSelected}
              loading={marketplaceLoading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SpModal;
