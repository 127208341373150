import { Avatar, Button, Drawer, Dropdown, Menu, Space } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DefaultAdminPage, DefaultUserPage } from "../config";
import { FindMenus } from "./menus";
import { BackToAdminWrapper, TopbarWrapper } from "./style";
import { Icon } from "@iconify/react/dist/iconify.js";
const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};
export default function Topbar({
  type,
  location,
  handleLogout,
  user,
  setUser,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { email, name } = user?.user?.user_data;

  const [current, setCurrent] = useState(location?.pathname?.replace("/", ""));
  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    setCurrent(location?.pathname?.replace("/", ""));
  }, [location]);

  const menu = (
    <Menu>
      <Menu.Item key="6">
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            {/*begin::Avatar*/}
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src="/assets/media/avatars/300-16.jpg" />
            </div>
            {/*end::Avatar*/}
            {/*begin::Username*/}
            <div className="d-flex flex-column">
              <div className="fw-bold d-flex align-items-center fs-5">
                {name}
              </div>
              <a
                href="#"
                className="fw-semibold text-muted text-hover-primary fs-7"
              >
                {email}
              </a>
            </div>
            {/*end::Username*/}
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        onClick={() => {
          navigate("/user-profile");
        }}
        className="py-4"
      >
        My Profile
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          navigate("/callback_spapi");
        }}
        key="11"
        className="py-4"
      >
        Marketplace Credential
      </Menu.Item>

      <Menu.Item
        onClick={() => {
          navigate("/mapping");
        }}
        key="12"
        className="py-4"
      >
        Mapping
      </Menu.Item>
      <Menu.Divider />
      <SubMenu key="sub1" title="Application Log" className="py-4">
        <Menu.Item
          onClick={() => {
            navigate("/application-log/central-log");
          }}
          key="2-1"
        >
          Central Log
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            navigate("/application-log/system-log");
          }}
          key="2-2"
        >
          System Error Log
        </Menu.Item>
      </SubMenu>
      {/* <Menu.Item key="3" className="py-4">
        Application Log
      </Menu.Item> */}

      <Menu.Item key="4">
        <Button
          onClick={handleLogout}
          style={{ height: "38px" }}
          className="w-100"
          type="primary"
        >
          Sign Out
        </Button>
      </Menu.Item>
    </Menu>
  );
  return (
    <TopbarWrapper id="kt_app_header" className="app-header ">
      <>
        <div
          className="app-container container-fluid d-flex align-items-stretch justify-content-between"
          id="kt_app_header_container"
        >
          <div
            className="align-items-center d-lg-none d-md-flex d-flex ms-n2 me-2"
            title="Show sidebar menu"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icon
              icon="lets-icons:menu"
              width={20}
              height={20}
              style={{ color: "#FFF" }}
            />
          </div>

          <div
            className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-13"
            style={{ width: "165px" }}
          >
            <Link to="/">
              <div className="d-flex align-items-center">
                <img
                  alt="Logo"
                  src="/assets/media/icon-white.png"
                  className="h-40px shimmer flipper"
                />
                <img
                  alt="Logo"
                  src="/assets/media/logo-white-ss.png"
                  className="h-45px shimmer slide-logo"
                />
              </div>
            </Link>
          </div>

          <div
            className="d-flex align-items-center justify-content-between flex-lg-grow-1"
            id="kt_app_header_wrapper"
          >
            <div
              className="app-header-menu app-header-mobile-drawer    align-items-center d-lg-flex d-md-none d-none"
              style={{ width: "calc(100vw - 600px)" }}
            >
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                className="w-100"
                mode="horizontal"
                items={FindMenus(type)?.map((d) => ({
                  ...d,
                  label: d.children ? (
                    d.label
                  ) : (
                    <Link to={d.key}>{d.label}</Link>
                  ),
                  children: d.children?.map((r) => ({
                    ...r,
                    label: <Link to={r.key}>{r.label}</Link>,
                  })),
                }))}
              />
            </div>

            <div className="app-navbar flex-shrink-0">
              {user?.admin && (
                <div className="d-flex align-items-center align-items-stretch mx-3">
                  <BackToAdminWrapper
                    onClick={() => {
                      setUser(user?.admin);
                      navigate(findRoutes?.[user?.admin?.loginType]);
                    }}
                  >
                    <span className="circle1" />
                    <span className="circle2" />
                    <span className="circle3" />
                    <span className="circle4" />
                    <span className="circle5" />
                    <span className="text ">
                      <div className="d-lg-block d-md-none d-none">
                        Back to Admin
                      </div>
                      <div className="d-lg-none d-md-block d-block">Admin</div>
                    </span>
                  </BackToAdminWrapper>
                  {/* <div
                  id="kt_header_search"
                  className="header-search d-flex align-items-center w-lg-200px"
                >
                  <div className="search-toggle-mobile d-flex d-lg-none align-items-center">
                    <div className="d-flex">
                      <i className="ki-outline ki-magnifier fs-1" />
                    </div>
                  </div>

                  <form
                    className="d-none d-lg-block w-100 position-relative mb-5 mb-lg-0"
                    autoComplete="off"
                  >
                    <input type="hidden" />

                    <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-300 position-absolute top-50 translate-middle-y ms-5" />

                    <input
                      type="text"
                      className="search-input form-control form-control rounded-1 ps-13"
                      name="search"
                      placeholder="Search..."
                    />

                    <span
                      className="search-spinner position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5"
                      data-kt-search-element="spinner"
                    >
                      <span className="spinner-border h-15px w-15px align-middle text-gray-500" />
                    </span>

                    <span
                      className="search-reset btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
                      data-kt-search-element="clear"
                    >
                      <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                    </span>
                  </form>
                </div> */}
                </div>
              )}

              {/*end::Chat*/}
              {/*begin::User menu*/}

              <Dropdown
                trigger={["click"]}
                overlay={menu}
                placement="bottomRight"
              >
                <Button type="text" style={{ padding: 0 }}>
                  <Avatar
                    src="/assets/media/avatars/300-16.jpg" // Update with your image path
                    size={45}
                  />
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </>
      <Drawer
        title="Basic Drawer"
        placement={"left"}
        headerStyle={{ display: "none" }}
        width={220} // Set the initial width
        closable={false}
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="app-header-menu app-header-mobile-drawer    align-items-center d-flex">
          <Menu
            onClick={(e) => {
              setOpen(false);
              onClick(e);
            }}
            selectedKeys={[current]}
            className="w-100"
            items={FindMenus(type)?.map((d) => ({
              ...d,
              label: <Link to={d.key}>{d.label}</Link>,
            }))}
          />
        </div>
      </Drawer>
    </TopbarWrapper>
  );
}
