import CentralLog from "../modules/users/application-logs/central-log.js";
import SystemLogs from "../modules/users/application-logs/system-log.js";
import CreateOrder from "../modules/users/create-order/index.js";
import Dashboard from "../modules/users/dashboard";
import FinancialSummary from "../modules/users/financial-summary";
import Insights from "../modules/users/insights";
import Inventory from "../modules/users/inventory";
import ItemMaster from "../modules/users/item-master/index.js";
import ComponentList from "../modules/users/item-master/lib/component-list.js";
import Mapping from "../modules/users/mapping/index.js";
import MarketplaceCredentials from "../modules/users/marketplace-credentials/index.js";
import ProfilePage from "../modules/users/profile";
import Purchase from "../modules/users/purchase";
import SubscribeAndSave from "../modules/users/subscribe-&-save";
import Variance from "../modules/users/variance";

export const Users = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "insights",
    element: <Insights />,
  },
  {
    path: "variance",
    element: <Variance />,
  },
  {
    path: "purchase",
    element: <Purchase />,
  },
  {
    path: "inventory",
    element: <Inventory />,
  },
  {
    path: "subscribe-&-save",
    element: <SubscribeAndSave />,
  },
  {
    path: "financial-summary",
    element: <FinancialSummary />,
  },
  {
    path: "user-profile",
    element: <ProfilePage />,
  },
  {
    path: "callback_spapi",
    element: <MarketplaceCredentials />,
  },

  {
    path: "callback_ads",
    element: <MarketplaceCredentials />,
  },
  {
    path: "application-log/central-log",
    element: <CentralLog />,
  },
  {
    path: "application-log/system-log",
    element: <SystemLogs />,
  },
  {
    path: "mapping",
    element: <Mapping />,
  },
  {
    path: "item-master",
    element: <ItemMaster />,
  },
  {
    path: "component-list",
    element: <ComponentList />,
  },
  {
    path: "create-order",
    element: <CreateOrder />,
  },
];
