import React from "react";
import { CompoenntWrapper } from "../style";
import { Button, Flex, Input, Select, Space } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";

const ComponentList = () => {
  return (
    <CompoenntWrapper>
      <Flex justify="space-between">
        <Flex wrap gap={10}>
          <Select
            size="large"
            options={[
              { label: "All", value: "1" },
              { label: "Enabled", value: "2" },
              { label: "Disabled", value: "3" },
            ]}
            placeholder="Status"
          />
          <Input className="w-250px" placeholder="Search By Title etc..." />
          <Select
            size="large"
            options={[
              { label: "Filter 1", value: "1" },
              { label: "Filter 2", value: "2" },
              { label: "Filter 3", value: "3" },
            ]}
            placeholder="Saved Filters"
          />
          <Button type="primary">
            <Icon width={22} icon="iconamoon:search-duotone" />
          </Button>
          <Button color="primary" variant="dashed">
            <Icon width={22} icon="solar:filter-bold-duotone" />
          </Button>
          <Button color="danger" variant="filled">
            <Icon width={22} icon="hugeicons:filter-reset" />
          </Button>
        </Flex>
        <Button type="primary">+ Add Component</Button>
      </Flex>
    </CompoenntWrapper>
  );
};

export default ComponentList;
