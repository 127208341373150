import { DatePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-6, "d").subtract(1, "d"), dayjs().subtract(1, "d")],
  },
  {
    label: "MTD",
    value: [dayjs().startOf("month"), dayjs().subtract(2, "d")],
  },
  {
    label: "Last 60 Days",
    value: [dayjs().add(-60, "d"), dayjs().subtract(2, "d")],
  },
  {
    label: "Last Month",
    value: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
  {
    label: "YTD",
    value: [dayjs().startOf("year"), dayjs().subtract(2, "d")],
  },
  {
    label: "Custom Range",
    value: [0, 0],
  },
];
const RangePickerCF = (props) => {
  const { presets, id, value, ...rest } = props;
  const [isOpen, setIsOpen] = useState(false);

  const getformat = (value) => {
    return moment(value).format("YYYY-MM-DD");
  };

  useEffect(() => {
    const element = document
      .getElementsByClassName(id)?.[0]
      ?.getElementsByClassName("ant-picker-presets");

    if (element && isOpen) {
      const isActive = (presets || rangePresets || [])
        ?.map((d) => {
          const presetsDates = d?.value;
          return {
            ...d,
            selected:
              getformat(presetsDates?.[0]?.$d) === getformat(value?.[0]?.$d) &&
              getformat(presetsDates?.[1]?.$d) === getformat(value?.[1]?.$d),
          };
        })
        .findIndex((d) => d?.selected === true);
      const nodes = element?.[0]?.childNodes?.[0]?.childNodes;
      let rangeList =
        isActive === -1 ? nodes?.[nodes?.length - 1] : nodes?.[isActive];
      const pastSelected = document
        .getElementsByClassName(id)?.[0]
        ?.querySelectorAll(".antd_selected_presets_date");

      if (pastSelected?.length !== 0) {
        pastSelected?.[0]?.classList.remove("antd_selected_presets_date");
      }
      rangeList?.classList.add("antd_selected_presets_date");
    }
  }, [isOpen]);

  return (
    <RangePicker
      presets={presets || rangePresets || []}
      value={value}
      id={id}
      popupClassName={id}
      suffixIcon={
        <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
          <span className="path6" />
        </i>
      }
      onOpenChange={() => setIsOpen(!isOpen)}
      {...rest}
    />
  );
};

export default RangePickerCF;
